import { navigate } from "gatsby";
import { KEY_CRYPT } from 'utils/config'
import { ENV } from "./config";
import Encryption from "./Encryption";

const UNAUTHORIZED = 401;
const INTERNAL_SERVER_ERROR = 500;

export const handleResponse = (response, doCallLogout = true) => {
  //Response have data if using axios 
  if(response.data){
    const data = response.data;
    let newData;
    if( data.substring(0, 20) == 'eyJjaXBoZXJ0ZXh0Ijoi' ) {
      const crypt = new Encryption();
      const dataCrypted = crypt.decrypt(data, KEY_CRYPT);
      newData = dataCrypted && JSON.parse(dataCrypted);
      writeResponse(newData)
    }
    else{
      newData = data;
      console.warn("Cette réponse n'est pas crypté, faites attention qu'elle ne contient aucune information sensible");
    }
    return newData;
  }
  else{  
    return response.text().then((text) => {
      let data;
      if( text.substring(0, 1) != '{' && text.substring(0, 20) == 'eyJjaXBoZXJ0ZXh0Ijoi' ) {
        const crypt = new Encryption();
        const dataCrypted = crypt.decrypt(text, KEY_CRYPT);
        data = dataCrypted && JSON.parse(dataCrypted);
        // Uncrypted response from api, dev purpose
        writeResponse(data);
      }
      else {
        data = text && JSON.parse(text);
        // TODO : change this console.warn
        console.warn("Cette réponse n'est pas crypté, faites attention qu'elle ne contient aucune information sensible");
      }
      if (!response.ok) {
        switch (response.status) {
          case UNAUTHORIZED:
          case INTERNAL_SERVER_ERROR:
            if(localStorage.getItem("userFront")){ // If userFront, user already logged --> disconnect
              doCallLogout && callLogout();
            }
            else{ // User not logged --> redirect to login without delete cookie
              navigate("/l");
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
      }
      return data;
    });
  };
}

const writeResponse = (data) => {
  if(['local', 'bis.test', 'staging', 'dimoret'].includes(ENV)){
    console.log(`%c <- Response - ${data}`, "color: coral;", data)
  }
}

export const handleCatch = (msg) => {
  console.error(msg);
}

const callLogout = () => {
  navigate("/l/logout");
}