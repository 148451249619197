import { API_ROOT, A2VOTE } from "utils/config";

export function getUserToken() {
  return localStorage.getItem("token");
}

export function getAuthorization() {
  return `Bearer ${getUserToken()}`;
}

export const getApiRoot = () => {
  return API_ROOT;
};

export const getA2Vote = () => {
  return A2VOTE;
};
